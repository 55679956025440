import React, { useState } from "react"
import NextImage from "next/image"
import { ImageProps } from "next/image"
import classNames from "classnames"

const DEFAULT_IMAGE_SRC = "/web-catalog-app/assets/default-logo.png"

interface Props extends ImageProps {
  style?: React.CSSProperties
}

export const Image = (props: Props): JSX.Element => {
  const { style, src, className, ...rest } = props

  const [loadingError, setLoadingError] = useState(false)

  const image = (
    <NextImage
      onError={() => {
        setLoadingError(true)
      }}
      src={loadingError || !src ? DEFAULT_IMAGE_SRC : src}
      {...rest}
    />
  )

  return (
    <div className={classNames("next-image-fix", className)} style={style}>
      {image}
    </div>
  )
}
